import "../Styles/Pages.css";
import Header from "../Components/Header";
import Map from "../Components/Map";

export default function FindUs() {
  return (
    <div className="page_container">
      <Header />
      <h1>Find Us</h1>
      <Map />
    </div>
  );
}
