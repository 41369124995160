import { useEffect, useState } from "react";
import { collection, getDocs, doc, updateDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { app, db } from "../utils/firebaseConfig.js";
import { useNavigate } from "react-router-dom";
import Header from "../Components/Header.js";
import useFirestoreCollection from "../utils/useFirestoreCollection.js";
import "../Styles/Pages.css";
import Switch from "react-switch";
import WorkingHours from "../Components/WorkingHours.js";
import UpdateWorkingHours from "../Components/UpdateWorkingHours.js";
import { FaPencilAlt } from "react-icons/fa";

const AdminPage = () => {
  const [editWorkingHours, setEditWorkingHours] = useState(false);
  const [news, setNews] = useState([]);
  const [user, setUser] = useState(null);
  const [isWinter, setIsWinter] = useState(true);
  const [editedNews, setEditedNews] = useState("");
  const [closingDate, setClosingDate] = useState(new Date());
  const [bookings, setBookings] = useState(false);
  const { data: workingHoursData } = useFirestoreCollection("hours");
  const {
    loading: adminLoading,
    error: adminError,
    data: adminData,
  } = useFirestoreCollection("administration"); //change to administration for production or testAdmin for testing

  useEffect(() => {
    if (adminData && adminData.length > 0) {
      setIsWinter(adminData[0].isWinter);
      setNews(adminData[0].news);
      setClosingDate(adminData[0].closingDate.toDate());
      setBookings(adminData[0].bookings);
    }
  }, [adminData]);

  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth(app);
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // User is authenticated, set the user state
        setUser(user);
      } else {
        // User is not authenticated, redirect to the login page or handle as needed

        // You can use React Router or any other method to navigate to the login page
        setUser(null);
        navigate("/login");
      }
    });

    return () => {
      unsubscribe();
    };
  }, [navigate]);

  const handleEditWorkingHoursToggle = () => {
    setEditWorkingHours((prevEditWorkingHours) => !prevEditWorkingHours);
  };

  const handleNewsUpdate = async () => {
    try {
      const docRef = doc(db, "administration", "Eo7tmrlLCYed7S4T1xQ0"); //change the ID to the ID of the document in the production database
      await updateDoc(docRef, {
        news: editedNews,
      });
      setNews(editedNews);
    } catch (error) {
      console.log("Error updating news:", error);
    }
  };

  const handleSeasonalStatusToggle = async () => {
    const confirmed = window.confirm("Είστε σίγουροι;");
    if (confirmed) {
      try {
        const docRef = doc(db, "administration", "Eo7tmrlLCYed7S4T1xQ0"); //change the ID to the ID of the document in the production database
        await updateDoc(docRef, {
          isWinter: !isWinter,
        });
        setIsWinter(!isWinter);
      } catch (error) {
        console.log("Error toggling seasonal status:", error);
      }
    }
  };

  if (!user) {
    return <div>You are not authenticated.</div>;
  }

  return (
    <div>
      <Header />
      <h3>Πίνακας Διαχειριστή</h3>
      <hr />
      {adminLoading ? (
        <p>Loading...</p>
      ) : (
        <div>
          {isWinter ? (
            <h4>Χειμώνας -- ΚΛΕΙΣΤΑ</h4>
          ) : (
            <h4>Καλοκαίρι -- ΑΝΟΙΚΤΑ</h4>
          )}
          <Switch
            checked={!isWinter}
            onChange={handleSeasonalStatusToggle}
            offColor="#ff0000"
          />
          <hr />
        </div>
      )}
      <div className="working-hours">
        {editWorkingHours ? (
          <UpdateWorkingHours
            handleEditWorkingHoursToggle={handleEditWorkingHoursToggle}
          />
        ) : (
          <>
            <WorkingHours />
            <button
              onClick={handleEditWorkingHoursToggle}
              className="edit-button"
            >
              <FaPencilAlt />
            </button>
            <hr />
            <div className="news">
              <h4>Νέα</h4>
              <input
                type="text"
                value={editedNews}
                placeholder={news}
                onChange={(e) => setEditedNews(e.target.value)}
              />
              <button onClick={handleNewsUpdate} className="edit-button">
                <FaPencilAlt />
              </button>
              <hr />
            </div>
            <div className="closing_date">
              <h4>Ημερομηνία Κλεισίματος</h4>
              <p>{closingDate.toLocaleString()}</p>
              <hr />
            </div>
            <div className="bookings">
              <h4>Δυνατότητα κρατήσεων;</h4>
              {bookings ? <p>Ναι</p> : <p>Όχι</p>}
              <hr />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AdminPage;
