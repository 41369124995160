import React, { useState, useEffect } from "react";
import { doc, updateDoc, writeBatch } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { db } from "../utils/firebaseConfig.js";
import useFirestoreCollection from "../utils/useFirestoreCollection";
import "../Styles/Admin.css";

const UpdateWorkingHours = ({ handleEditWorkingHoursToggle }) => {
  const { loading, error, data } = useFirestoreCollection("hours");
  const [workingHours, setWorkingHours] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      setWorkingHours(data);
    }
  }, [data]);

  // Function to handle checkbox change
  const handleCheckboxChange = (dayIndex) => {
    setWorkingHours((prevWorkingHours) =>
      prevWorkingHours.map((day, index) =>
        index === dayIndex ? { ...day, isOpen: !day.isOpen } : day
      )
    );
  };

  // Function to handle input change
  const handleInputChange = (e, dayIndex) => {
    const { value } = e.target;
    setWorkingHours((prevWorkingHours) =>
      prevWorkingHours.map((day, index) =>
        index === dayIndex ? { ...day, opening: value } : day
      )
    );
  };

  // Function to handle form submit
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const batch = writeBatch(db);
      workingHours.forEach((day) => {
        const docRef = doc(db, "hours", day.id);
        batch.update(docRef, {
          isOpen: day.isOpen,
          opening: day.opening,
        });
      });

      await batch.commit();
      console.log("Working hours updated successfully!");
      handleEditWorkingHoursToggle();
    } catch (error) {
      console.log("Error updating working hours:", error);
    }
  };

  return (
    <div className="update-working-hours">
      {loading && <p>Loading...</p>}
      {error && console.log(error)}
      {!loading && !error && (
        <form onSubmit={handleFormSubmit}>
          <ul className="working-hours-list">
            {workingHours.map((day, index) => (
              <li key={day.id} className="working-hours-item">
                <div className="day-container">
                  <span>{day.day}:</span>
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      checked={day.isOpen}
                      onChange={() => handleCheckboxChange(index)}
                    />
                    Ανοικτά
                  </label>
                </div>
                <input
                  type="text"
                  value={day.opening}
                  onChange={(e) => handleInputChange(e, index)}
                />
              </li>
            ))}
          </ul>
          <button type="submit">Ενημέρωση Ωραρίου</button>
        </form>
      )}
    </div>
  );
};

export default UpdateWorkingHours;
